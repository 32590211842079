<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="NewItem.alt"
                color="custom-accent darken-4"
                :label="$t('alttext')"
                :placeholder="$t('alttext')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default({
    
    props: ['NewItem'],
    data() {
        return {
            valid: false,
        }
    },
    components: { 
    },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        NewItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        validate(){
            let isValid = this.$refs.form.validate() && this.NewItem.image;
            this.$emit('isValid','create',isValid);
        }
    }
})
</script>
<style scoped>
</style>
