<template>
  <v-container id="gallery" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'gallery-bg.jpg' + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{
              $t("gallery")
            }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" md="10" lg="8" class="ma-auto">
            <transition name="fade" mode="out-in" v-if="gallery_loading">
              <v-row class="px-8 ma-0" justify="center">
                <v-col
                  v-for="i in itemsperpage"
                  :key="i"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-skeleton-loader
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="gallery.length==0 && !gallery_loading">
              <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="gallery.length && !gallery_loading">
              <v-row  class="px-8 ma-0" justify="center" :key="itemsperpage * page">
                <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('gallery.create')"
                  cols="12">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateGallery()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                    </template>
                    <span>{{$t('create')}}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  v-for="(item,i) in gallery.slice(
                    itemsperpage * (page - 1),
                    itemsperpage * page
                  )"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <v-card class="mx-auto elevation-0">
                    <v-row class="pa-0 ma-0" style="position:absolute;right:0px;top:0px;z-index:3;" v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('gallery.delete')">
                        <v-col class="pa-0 ma-0" cols="12">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn depressed dark class="pa-0 ma-0" v-bind="attrs" v-on="on" @click.stop="OpenDeleteGallery(item)" :title="$t('delete')">
                                <v-icon class="pa-0 ma-0">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{$t('delete')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                          <v-img
                            :src="storage_url+path+item.image"
                            :alt="item.alt"
                            aspect-ratio="1"
                            class="image"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="custom-secondary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          <v-fade-transition>
                            <v-overlay @click="openFullscreen(i+(page-1)*itemsperpage)"
                              v-if="hover"
                              absolute
                              color="custom-accent"
                            >
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click.stop="openFullscreen(i+(page-1)*itemsperpage)" icon :title="$t('fullscreen')"><v-icon large>mdi-arrow-expand</v-icon></v-btn>
                                </template>
                                <span>{{$t('fullscreen')}}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </template>
                    </v-hover>
                  </v-card>
                </v-col>
              </v-row>
            </transition>
            <v-row class="pa-0 ma-0 mb-4">
              <v-col v-if="Math.ceil(gallery.length / itemsperpage)>1">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(gallery.length / itemsperpage)"
                  color="custom-accent"
                  :total-visible="7"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- view dialog -->
        <v-dialog v-model="imagefullscreenoverlay" max-width="1100px">
          <v-card class="custom-off-white pt-2 overflow-hidden">
          <v-tooltip left color="custom-secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                absolute
                top
                right
                style="z-index: 3;top:-1px;right:-2px"
                v-bind="attrs"
                v-on="on"
                :title="$t('close')"
                @click="imagefullscreenoverlay = false"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("close") }}</span>
          </v-tooltip>
            <v-carousel v-model="index" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in gallery"
                :key="i"
                :src="storage_url+path+item.image"
                contain
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <v-slide-group
              v-model="index"
              center-active
              mandatory
              show-arrows
            >
              <v-slide-item
                v-for="(image,i) in gallery"
                :key="i" 
                v-slot="{ active }"
              >
                <v-card
                  class="ma-4"
                  :class="active?'active-preview my-2':'preview'"
                  width="100"
                  height="100"
                  @click="openFullscreen(i)"
                >
                  <v-img
                    :src="storage_url+path+image.image"
                    :alt="image.alt"
                    aspect-ratio="1"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="custom-secondary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </v-dialog>
        <!-- view dialog -->

        <!-- create dialog -->
        <v-dialog v-model="gallerycreateoverlay" width="800px">
          <v-card v-if="gallerycreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <file-input-drop @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <create-gallery @isValid="validate" :NewItem="activegallery"></create-gallery>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="gallerycreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearGallery">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="gallery_loading || file_loading" :disabled="!gallerycreateformvalid" @click="CreateGallery()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="gallerydeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="gallerydeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="gallery_loading || file_loading" depressed @click="DeleteGallery()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
import creategallery from '../../components/Gallery/CreateGallery.vue';
import fileinputdrop from "../../components/Admin/FileInputDrop.vue";
export default {
  name: "Gallery",

  data() {
    return {
      index: 0,
      imagefullscreenoverlay: false,
      page: 1,
      itemsperpage: 9,

      gallerycreateoverlay: false,
      gallerydeleteoverlay: false,
      gallerycreateformvalid: false,
      expandableGallery: null,
      activegallery: {},
      
      allowedTypes: "image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "268435456",
      allowedLength: 20,
      multiple:false,
      files:[],

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "blogs/gallery/images/",
    };
  },
  components: {
    'create-gallery':creategallery,
    'file-input-drop':fileinputdrop,
  },
  async created() {
  },
  computed: {
    ...mapGetters('gallery', {gallery:'getGallery'}),
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapState("gallery", {gallery_loading: "loading"}),
    ...mapState("file", {file_loading: "loading"}),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.gallerycreateformvalid=isValid;
            break;

        default:
            break;
      }
    },

    OpenCreateGallery() {
      this.gallerycreateoverlay = true;
      this.activegallery = {};
    },
    OpenDeleteGallery(item) {
      this.gallerydeleteoverlay = true;
      this.activegallery = {...item};
    },
    openFullscreen(index){
      this.index = index;
      this.imagefullscreenoverlay = true;
    },
    CreateGallery() {
      if(this.gallerycreateformvalid && this.user && this.activegallery.image && this.user.permission.map(i=>i.name).includes('gallery.create')){
        const formData = new FormData();
        formData.append("path", this.path);
        for (let index = 0; index < this.files.length; index++) {
          let file = this.files[index];
          formData.append("data[" + index + "]", file);
        }
        this.addFile(formData).then((response)=>{
          if(!this.multiple)
            this.activegallery.image = response.data[0].split(';')[0];
          this.addGallery(this.activegallery).then(() => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
              this.gallerycreateoverlay = false;
              this.activegallery = {};
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        },() => {
          this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
        });
      }
    },
    DeleteGallery() {
      if(this.user && this.user.permission.map(i=>i.name).includes('gallery.delete')){
        this.deleteGallery(this.activegallery).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.gallerydeleteoverlay = false;
            this.activegallery = {};
            if(response.data.image){
              let payload ={name:response.data.image, path:this.path};
              this.deleteFile(payload);
            }
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearGallery() {
      this.activegallery={image:this.activegallery.image};
    },
    UploadFiles(files) {
      this.files=files;
      if(!this.multiple)
        this.$set(this.activegallery, 'image', this.files[0]?.name);
    },
    
    ...mapActions("gallery", ["addGallery","deleteGallery"]),
    ...mapActions("file", ["addFile","deleteFile"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/gallery.css");
</style>